/* CSS */
import "../bootstrap/css";

/* loading & showing loading */
import loading, {
    showLoading,
    hideLoading,
    showSlowLoading,
    hideSlowLoading,
} from "../bootstrap/loading";

/* config and my-assets */
import { TimeOut, AppInfo } from "../configs/base";
import vi from "../public/vi.json";

/* 3rd-utils */
import { createI18n } from "vue-i18n";
import { locale, loadMessages } from "devextreme/localization";
import mitt from "mitt";

/* my-helpers */
import $Core from "../bootstrap/$Core";
import Helper from "./utils/Helper";
import Events from "./utils/Events";
import AppClass from "./utils/App";

/* app-logic */
import vuex from "./vuex";
import createRouter from "./router";

/* vue3-utils */
import { createStore } from "vuex";
import { createApp } from "vue";

// Moment
import moment from "moment";
//plugin
import moneyFormat from "./utils/money-format";

//ionic vue
import { IonicVue } from "@ionic/vue";
import App from "./App.vue";
// import routerIonic from './router';

//css ionic
/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";
/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* define 3rd-utils */
const $i18n = createI18n({
    locale: "string",
    fallbackLocale: "en",
    globalInjection: true,
    messages: {
        string: require("../public/string.json"),
    },
});
const emitter = mitt();

/* define my-utils */
const $Helper = new Helper({
    $Core: $Core,
    emitter: emitter,
});
const $AppClass = new AppClass($Core);

const store = createStore(vuex);

/* debug */
window.$Core = $Core;
window.$store = store;
window.$Helper = $Helper;
window.$AppClass = $AppClass;
window.$loading = loading;
window.$emitter = emitter;
window.$i18n = $i18n;
window.$MoneyFormat = moneyFormat;
window.$router = createRouter($Core);
var timeOutSlowLoading = null;

/* init Events */
const $Events = new Events({ $Core: $Core, emitter: emitter, $store: store });

/* main-handler */
(async function SonPhatNumberOne(app) {
    // console.log("READY", await $Core.Auth.Ready());

    const router = createRouter($Core);

    app.config.globalProperties.$appInfo = AppInfo;
    app.config.globalProperties.emitter = emitter;
    app.config.globalProperties.moment = moment;
    app.use(router);
    app.use($i18n);
    app.use(moneyFormat);
    app.use({
        install(app) {
            let checkTimeOutLoading = null;
            let prototype = app.config.globalProperties;
            function protoDefProp(propName, getter, setter) {
                let propMap = {};
                getter && (propMap.get = getter);
                setter && (propMap.get = setter);
                Object.defineProperty(prototype, propName, propMap);
            }
            protoDefProp("$startLoading", () => {
                emitter.emit("startLoading");
            });
            protoDefProp("$stopLoading", () => {
                emitter.emit("stopLoading");
            });
            protoDefProp("$showLoading", () => {
                showLoading();
                checkTimeOutLoading = setTimeout(() => {
                    clearTimeout(checkTimeOutLoading);
                    hideLoading();
                }, TimeOut);
                return true;
            });
            protoDefProp("$hideLoading", () => {
                clearTimeout(checkTimeOutLoading);
                hideSlowLoading();
                hideLoading();
                return true;
            });
            // protoDefProp("$startLoading", () => {
            //     clearTimeout(timeOutSlowLoading);
            //     showLoading();
            //     timeOutSlowLoading = setTimeout(() => {
            //         showSlowLoading();
            //     }, 5000);
            //     return true;
            // });
            // protoDefProp("$stopLoading", () => {
            //     clearTimeout(timeOutSlowLoading);
            //     hideSlowLoading();
            //     hideLoading();
            //     return true;
            // });
            protoDefProp("$Core", () => $Core);
            protoDefProp("$Helper", () => $Helper);
            protoDefProp("$Events", () => $Events);
            protoDefProp("$AppClass", () => $AppClass);
            protoDefProp("$Nope");
            protoDefProp("$MoneyFormat", () => moneyFormat);

            // store
            store.startLoading = () => {
                emitter.emit("startLoading");
            };
            store.stopLoading = () => {
                emitter.emit("stopLoading");
            };
            store.ThongBaoPopup = (params) => {
                emitter.emit("ThongBaoPopup", params);
            };
            store.$Core = $Core;
            store.$Helper = $Helper;
            store.$Events = $Events;
            store.$AppClass = $AppClass;
            store.$i18n = $i18n;
        },
    });

    app.use(store);
    app.use(IonicVue, {
        animated: true,
    });
    // app.use(routerIonic);

    // app.mount("#app");

    loadMessages(vi);
    locale("vi");

    router.isReady().then(() => {
        app.mount("#app");
        hideLoading();
    });
})(createApp(App, {}));
