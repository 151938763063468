export default {
    namespaced: true,
    state: () => ({
        DangXuat: false,
    }),
    getters: {},
    mutations: {
        Set(state, { key, data }) {
            state[key] = data;
        },
        Unset(state, { key }) {
            delete state[key];
        }
    },

    actions: {
        async XoaTaiKhoan(store, { tenTaiKhoan }) {
            try {
                let rs = await this.$Core.Api.TaiKhoan(
                    this.$i18n.global.t("urlTaiKhoan.XoaTaiKhoan")
                ).Post({
                    tenTaiKhoan: tenTaiKhoan
                });
                let errorCode = rs.Data.errorCode;
                if (errorCode == 0) {
                    return rs.Data || {};
                } else {
                    return {};
                }
            } catch (error) {
                console.log("🚀 ~ ", error);
            }
        },
        async KhoiPhucTaiKhoan(store, { tenTaiKhoan }) {
            try {
                let rs = await this.$Core.Api.TaiKhoan(
                    this.$i18n.global.t("urlTaiKhoan.KhoiPhucTaiKhoan")
                ).Post({
                    tenTaiKhoan: tenTaiKhoan
                });
                let errorCode = rs.Data.errorCode;
                if (errorCode == 0) {
                    return rs.Data || {};
                } else {
                    return {};
                }
            } catch (error) {
                console.log("🚀 ~ ", error);
            }
        },
        async ThongTinTaiKhoan(store, { tenTaiKhoan }) {
            try {
                let rs = await this.$Core.Api.TaiKhoan(
                    this.$i18n.global.t("urlTaiKhoan.ThongTinTaiKhoan")
                ).Get({
                    tenTaiKhoan: tenTaiKhoan
                });
                let errorCode = rs.Data.errorCode;
                if (errorCode == 0) {
                    return rs.Data || {};
                } else {
                    return {};
                }
            } catch (error) {
                console.log("🚀 ~ ", error);
            }
        }
    }
};

function set(store, key, data) {
    store.commit("Set", {
        key: key,
        data: data
    });
}
