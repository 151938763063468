<template>
    <div id="root">
        <div :class="cssClasses">
            <!-- <div style="position: fixed; top: 50%; z-index: 999">
                {{ showlog }} 123
            </div> -->

            <component
                :is="$route.meta.layout"
                :is-x-small="screen.getScreenSizeInfo.isXSmall"
                :is-large="screen.getScreenSizeInfo.isLarge"
            >
                <ion-router-outlet />
                <!-- <router-view></router-view> -->
            </component>
            <!-- ====================================Toast========================================= -->
            <DxToast
                v-model:visible="toast.visible"
                v-model:type="toast.type"
                width="auto"
                :position="{
                    my: 'top center',
                    at: 'top center',
                    offset: '0 15',
                }"
                class="toast-style"
            >
                <template #contentTemplate>
                    <ToastContentVue :toast="toast" />
                </template>
            </DxToast>

            <DxPopup
                v-model:visible="PopupCapNhatPhienBan"
                width="90%"
                height="auto"
                :show-title="false"
                :close-on-outside-click="false"
                :drag-enabled="false"
                :show-close-button="false"
            >
                <PopupCapNhatPhienBanVue
                    :Params="ParamsCapNhatPhienBan"
                    @DongPopup="DongPopup"
                />
            </DxPopup>

            <!-- <DxPopup
                v-if=""
                v-model:visible=""
                :drag-enabled="false"
                :close-on-outside-click="false"
                :show-title="false"
                :width="440"
                height="auto"
                @hidden="onHidden('PopupThongBao')"
            >
            </DxPopup> -->

            <PopupVue
                height="auto"
                v-if="ifPopupThongBao"
                v-model:dialog="PopupThongBao"
                title=""
                heightScrollView="100%"
                :useButtonLeft="false"
                :useButtonRight="false"
                class="popup-padding-16"
            >
                <template #content>
                    <NotificationVue
                        :Params="ParamsThongBao"
                        :ButtonLeft="false"
                        @click="XacNhanThongBao"
                    />
                </template>
            </PopupVue>
        </div>
    </div>
</template>

<script>
import { DxPopup } from "devextreme-vue/popup";
import { sizes, subscribe, unsubscribe } from "./utils/media-query";
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import ToastContentVue from "./components/_Common/ToastContent";
import { DxToast } from "devextreme-vue";
import { getCurrentInstance, reactive, onMounted, onBeforeUnmount, computed } from "vue";
import { mapActions, mapGetters } from "vuex";
import PopupCapNhatPhienBanVue from "./components/_Common/PopupCapNhatPhienBan.vue";
import NotificationVue from "@sonphat/common-v1/components/Notification.vue";
import PopupVue from "./components/_Common/Popup.vue";
function getScreenSizeInfo() {
    const screenSizes = sizes();

    return {
        isXSmall: screenSizes["screen-x-small"],
        isLarge: screenSizes["screen-large"],
        cssClasses: Object.keys(screenSizes).filter((cl) => screenSizes[cl]),
    };
}

export default {
    components: {
        IonApp,
        IonRouterOutlet,
        ToastContentVue,
        DxToast,
        DxPopup,
        PopupCapNhatPhienBanVue,
        NotificationVue,
        PopupVue,
    },
    setup() {
        const vm = getCurrentInstance();

        const title = vm.proxy.$appInfo.title;
        const screen = reactive({ getScreenSizeInfo: {} });
        screen.getScreenSizeInfo = getScreenSizeInfo();

        function screenSizeChanged() {
            screen.getScreenSizeInfo = getScreenSizeInfo();
        }

        onMounted(() => {
            subscribe(screenSizeChanged);
        });

        onBeforeUnmount(() => {
            unsubscribe(screenSizeChanged);
        });

        const cssClasses = computed(() => {
            return ["app"].concat(screen.getScreenSizeInfo.cssClasses);
        });

        return {
            title,
            screen,
            cssClasses,
        };
    },
    data() {
        return {
            toast: { visible: false, message: "", type: "success" },
            ParamsCapNhatPhienBan: {
                Message: "",
                CoPhienBanMoi: false,
                BatBuocCapNhat: false,
                LinkCapNhat: "",
            },
            PopupCapNhatPhienBan: false,
            showlog: "bt",
            version: null,
            MaUngDung: null,
            isAndroid: false,
            isIos: false,
            checkLoading: 0,
            ifPopupThongBao: false,
            PopupThongBao: false,
            ParamsThongBao: {
                state: "Error",
                button_save: "Đồng ý",
                button_cancel: "Hủy",
                title: "Cảnh báo!",
                message_title: "Bạn muốn thoát khỏi hệ thống?",
            },
        };
    },
    computed: {
        ...mapGetters("QrCode", ["dangQuetMa"]),
        typeButton() {
            let type = this.toast.type;

            let map = {
                success: "success",
                error: "danger",
                warning: "warning",
            };
            if (map.hasOwnProperty(type)) {
                return map[type];
            } else {
                return type;
            }
        },
        typeIconButton() {
            let type = this.toast.type;
            let map = {
                success: "check-circle-outline",
                error: "close-circle-outline",
                warning: "alert",
            };
            if (map.hasOwnProperty(type)) {
                return map[type];
            } else {
                return type;
            }
        },
    },
    watch: {
        version: {
            handler: function () {
                this.KiemTraCapNhat();
            },
            immediate: true,
            deep: true,
        },
        async dangQuetMa() {
            let rs = await this.kiemTraQrQuetMa();
            if (
                rs.check === true &&
                rs.codeCheck == this.$t("StringCheck.QrVe") &&
                this.$route.fullPath != "/tabs/tab3" &&
                this.$route.path != "/ban-ve-ghe-phu" &&
                this.$route.path != "/ban-ve-tren-xe"
            ) {
                this.$router.push({
                    path: "/tabs/tab3",
                });
            } else if (rs.check === false) {
                this.toast = {
                    message: rs.msg,
                    type: "error",
                    visible: true,
                };
            }

            if (
                rs.check === true &&
                rs.codeCheck == this.$t("StringCheck.QrSDT") &&
                this.$route.fullPath == "/tabs/tab1"
            ) {
                this.toast = {
                    message: "Mã QR không hợp lệ!",
                    type: "error",
                    visible: true,
                };
            }
        },
        checkLoading() {
            if (this.checkLoading == 0) {
                this.$hideLoading;
            }
            if (this.checkLoading > 0) {
                this.$showLoading;
            }
            if (this.checkLoading < 0) {
                this.checkLoading = 0;
            }
        },
        PopupThongBao() {
            if (!this.PopupThongBao) {
                setTimeout(() => {
                    this.ifPopupThongBao = false;
                }, 300);
            }
        },
    },
    methods: {
        ...mapActions("QrCode", ["nhanMaQR", "kiemTraQrQuetMa"]),
        DongPopup() {
            this.PopupCapNhatPhienBan = false;
        },
        async KiemTraCapNhat() {
            try {
                let query = {
                    MaUngDung: this.MaUngDung,
                    HeDieuHanh: this.isAndroid ? "Android" : this.isIos ? "IOS" : null,
                    SoPhienBan: this.version,
                };
                let rs = await this.$store.dispatch(
                    "ThongTin/LayThongTinPhienBan",
                    query,
                );

                if (rs.Data.status == true) {
                    let data = rs.Data.data;
                    this.PopupCapNhatPhienBan = data.CoPhienBanMoi;
                    this.ParamsCapNhatPhienBan.CoPhienBanMoi = data.CoPhienBanMoi;
                    this.ParamsCapNhatPhienBan.BatBuocCapNhat = data.BatBuocCapNhat;
                    this.ParamsCapNhatPhienBan.LinkCapNhat = data.LinkCapNhat;
                    this.ParamsCapNhatPhienBan.Message =
                        data.CoPhienBanMoi && data.BatBuocCapNhat
                            ? "Bạn cần cập nhật phiên bản mới để tiếp tục sử dụng ứng dụng!"
                            : data.CoPhienBanMoi
                            ? "Đã có phiên bản mới, bạn có muốn cập nhật ứng dụng?"
                            : "";
                    this.showlog = data.CoPhienBanMoi;
                }
            } catch (error) {
                console.log("🚀 ~ error", error);
            }
        },
        checkPhienBanChrome() {
            if (this.isAndroid) {
                var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
                let version = raw ? parseInt(raw[2], 10) : false;
                if (version < 85) {
                    this.$router.push("/Cap-Nhat-Webview");
                }
            }
        },
        remote() {
            let self = this;
            (function () {
                var s = document.createElement("script");
                s.src = "https://remotejs.com/agent/agent.js";
                s.setAttribute(
                    "data-consolejs-channel",
                    self.$Core.AuthApi.IsAuth
                        ? self.$Core.AuthApi.TokenParsed.sid
                        : navigator.deviceId
                        ? navigator.deviceId
                        : "00000000-0000-0000-0000-000000000000",
                );
                document.head.appendChild(s);
            })();
        },
        onHidden(name) {
            this["if" + name] = false;
        },
        showPopup(name) {
            this["if" + name] = true;
            this[name] = true;
        },
        XacNhanThongBao(bool) {
            if (bool == true) {
                this.PopupThongBao = false;
                this.emitter.emit("XacNhanThongBao", bool);
            }
        },
    },
    async mounted() {
        let self = this;
        this.emitter.on("startLoading", () => {
            self.checkLoading++;
        });
        this.emitter.on("stopLoading", () => {
            self.checkLoading--;
        });
        this.emitter.on(
            "ThongBaoPopup",
            ({
                type = "Error",
                title = "Thông báo",
                message_title = "",
                message = "",
                textButton = "Đồng ý",
            }) => {
                self.ParamsThongBao.state = type;
                self.ParamsThongBao.button_save = textButton;
                self.ParamsThongBao.title = title;
                self.ParamsThongBao.message_title = message_title;
                self.ParamsThongBao.message = message;
                self.showPopup("PopupThongBao");
                self.emitter.emit("ThemThongBaoLocal", {
                    message: message_title + message,
                    type: type ? type.toLowerCase() : "error",
                });
            },
        );
        setTimeout(() => {
            // this.remote();
            this.version =
                navigator.nativeVersion && navigator.nativeVersion.split("@").length > 1
                    ? navigator.nativeVersion.split("@")[1]
                    : null;

            this.MaUngDung =
                navigator.nativeVersion && navigator.nativeVersion.split("@").length > 0
                    ? navigator.nativeVersion.split("@")[0]
                    : null;

            let ua = navigator.userAgent.toLowerCase();
            this.isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");

            this.isIos =
                [
                    "iPad Simulator",
                    "iPhone Simulator",
                    "iPod Simulator",
                    "iPad",
                    "iPhone",
                    "iPod",
                ].includes(navigator.platform) ||
                (navigator.userAgent.includes("Mac") && "ontouchend" in document);
            this.checkPhienBanChrome();
        }, 1000);
        this.nhanMaQR();
        await this.$store.dispatch("ThongTin/LayThongTinViTri");

        // let rs = await SonPhat.getFirebaseCloudMessagingRegistrationToken();
        //     console.log("🚀 ~ file: App.vue ~ line 269 ~ setInterval ~ rs", rs);
    },
};
</script>

<style lang="scss">
html,
body {
    margin: 0px;
    min-height: 100%;
    height: 100%;
}

#root {
    height: 100%;
}

// * {
//     box-sizing: border-box;
// }

.app {
    @import "./themes/generated/variables.base.scss";
    background-color: #fff;
    display: flex;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}
</style>
