<template>
    <div>
        <div
            :class="`row justify-center ${
                state == 'Error'
                    ? 'danger--text'
                    : state == 'Warning'
                    ? 'warning--text'
                    : 'success--text'
            }`"
        >
            <i
                :class="`mdi mdi-${
                    state == 'Error'
                        ? 'alert-circle-outline'
                        : state == 'Warning'
                        ? 'alert'
                        : 'check-circle-outline'
                }`"
                style="font-size: 58px"
            ></i>
        </div>
        <div
            class="row justify-center text-xs-center font-bold mb-1"
            style="font-size: 18px"
        >
            {{ title }}
        </div>
        <div class="row justify-center text-xs-center mb-1">
            <div v-html="message"></div>
        </div>
        <slot name="content"></slot>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: "",
        },
        message: {
            type: String,
            default: "",
        },
        state: {
            type: String,
            default: "Error",
        },
    },
};
</script>
