export default {
    namespaced: true,
    state: () => ({
        viewPage: 1,
        ChiTietKeHoach: {
            TenTuyen: null,
            TenBenXeDi: null,
            Not: "00:00:00",
        },
        maxDate: new Date("2999-12-31T16:59:59.000Z"),
        minDate: new Date("1970-01-01T00:00:00.000Z"),
        danhSachLaiXe: [],
        laiXeTiepNhanLenh: {},
        bienKiemSoat: {},
        hoTenPhuXe: "",
        dialogHuyLenh: false,
        idLenhDienTu: "",

        DanhSachTieuChi: [
            {
                text: "Giờ XB",
                value: "GioXuatBen",
                order: 0,
            },
            {
                text: "BKS",
                value: "BienSoXe",
                order: 0,
            },
            {
                text: "Tuyến vận chuyển",
                value: "TenTuyen",
                order: 0,
            },
        ],
        GuidLenh: "",
        dialogTheHien: false,
        IdChuyenDiBanVeDangChon: "",
        dialogMatMaBaoMat: false,
        dialogKyLaiLenh: false,
        dialogDungHanhTrinh: false,
        itemLuaChonDungHanhTrinh: {
            MaSoLenh: "x",
            TenTrangThaiLenh: "x",
            GioXuatBen: "x",
            BienSoXe: "x",
            TenTuyen: "x",
            MaTuyen: "x",
        },
        NgayDuong: new Date(),
        TenTuyen: null,
        ChiTietCauHinhDoanhNghiepTheoMaCauHinh: {},
        ThongTinChinhSuaLenh: {},
    }),

    mutations: {
        Set(state, { key, data }) {
            state[key] = data;
        },
        Unset(state, { key }) {
            delete state[key];
        },
        SET_VIEWPAGE(state, data) {
            state.viewPage, data;
        },
        SET_CHI_TIET_KE_HOACH(state, data) {
            state.ChiTietKeHoach = data;
        },
        SET_DANH_SACH_LAI_XE(state, data) {
            state.danhSachLaiXe = data;
        },
        SET_LAI_XE_TIEP_NHAN_LENH(state, data) {
            state.laiXeTiepNhanLenh = data;
        },
        SET_BIEN_KIEM_SOAT(state, data) {
            state.bienKiemSoat = data;
        },
        SET_HO_TEN_PHU_XE(state, data) {
            state.hoTenPhuXe = data;
        },
        SET_DIALOG_LENH(state, data) {
            state.dialogHuyLenh = data;
        },
        SET_IDLENH(state, data) {
            state.idLenhDienTu = data;
        },
        SET_GUID_LENH(state, data) {
            state.GuidLenh = data;
        },
        SET_DIALOG_THEHIEN(state, data) {
            state.dialogTheHien = data;
        },
        SET_ID_CHUYEN_DI_BAN_VE_DANG_CHON(state, data) {
            state.IdChuyenDiBanVeDangChon = data;
        },
        SET_DIALOG_BAO_MAT(state, data) {
            state.dialogMatMaBaoMat = data;
        },
        SET_DIALOG_KY_LAI_LENH(state, data) {
            state.dialogKyLaiLenh = data;
        },
    },

    actions: {
        Reload_DanhSachChuyenDiDuKien(
            store,
            { textTimKiem, listFieldSearch, query, key }
        ) {
            store;

            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    this.startLoading();
                    let customLoadOptions = { ...loadOptions };
                    let itemTieuChi = store.state.DanhSachTieuChi.find(
                        (e) => e.order != 0
                    );
                    if (itemTieuChi != undefined) {
                        if ((itemTieuChi.value = "GioXuatBen")) {
                            itemTieuChi.value = "Not";
                        }
                    }
                    let filter = [];
                    if (textTimKiem) {
                        filter = listFieldSearch
                            .map((e) => [e, "contains", textTimKiem])
                            .reduce(
                                (filter, x, i, a) => (
                                    filter.push(x),
                                    i < a.length - 1 ? filter.push("or") : !0,
                                    filter
                                ),
                                []
                            );
                        customLoadOptions.filter = filter || null;
                    }

                    if (itemTieuChi) {
                        customLoadOptions.sort = [
                            {
                                desc: itemTieuChi.kieuSapXep == "DESC",
                                selector: itemTieuChi.value,
                            },
                        ];
                    }
                    try {
                        let resp = await this.$Core.Api.QuanLyLenh(
                            this.$i18n.global.t(
                                "urlDNQuanLyLenh.DanhSachChuyenDiDuKien"
                            )
                        ).Post({
                            custom: query,
                            loadOptions: customLoadOptions,
                        });
                        if (resp.StatusCode === 200) {
                            let result = resp.Data.data;
                            return result;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        return [];
                    } finally {
                        this.stopLoading();
                    }
                },
                key || null
            );
            return load;
        },
        Reload_DanhSachLenhDaCap(
            store,
            { textTimKiem, listFieldSearch, query, key }
        ) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    this.startLoading();
                    let customLoadOptions = { ...loadOptions };
                    let itemTieuChi = store.state.DanhSachTieuChi.find(
                        (e) => e.order != 0
                    );
                    if (itemTieuChi != undefined) {
                        if ((itemTieuChi.value = "Not")) {
                            itemTieuChi.value = "GioXuatBen";
                        }
                    }

                    let filter = [];
                    if (textTimKiem) {
                        filter = listFieldSearch
                            .map((e) => [e, "contains", textTimKiem])
                            .reduce(
                                (filter, x, i, a) => (
                                    filter.push(x),
                                    i < a.length - 1 ? filter.push("or") : !0,
                                    filter
                                ),
                                []
                            );
                        customLoadOptions.filter = filter || null;
                    }
                    if (itemTieuChi) {
                        customLoadOptions.sort = [
                            {
                                desc: itemTieuChi.kieuSapXep == "DESC",
                                selector: itemTieuChi.value,
                            },
                        ];
                    }
                    try {
                        let resp = await this.$Core.Api.QuanLyLenh(
                            this.$i18n.global.t(
                                "urlDNQuanLyLenh.DanhSachLenhDaCap"
                            )
                        ).Post({
                            loadOptions: customLoadOptions,
                            custom: query,
                        });
                        if (resp.StatusCode === 200) {
                            let result = resp.Data.data;
                            return result;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        return [];
                    } finally {
                        this.stopLoading();
                    }
                },
                key || null
            );
            return load;
        },
        Reload_DanhSachLenhDangThucHien(
            store,
            { textTimKiem, listFieldSearch, query, key }
        ) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    this.startLoading();
                    let customLoadOptions = { ...loadOptions };
                    let itemTieuChi = store.state.DanhSachTieuChi.find(
                        (e) => e.order != 0
                    );
                    if (itemTieuChi != undefined) {
                        if ((itemTieuChi.value = "Not")) {
                            itemTieuChi.value = "GioXuatBen";
                        }
                    }
                    let filter = [];
                    if (textTimKiem) {
                        filter = listFieldSearch
                            .map((e) => [e, "contains", textTimKiem])
                            .reduce(
                                (filter, x, i, a) => (
                                    filter.push(x),
                                    i < a.length - 1 ? filter.push("or") : !0,
                                    filter
                                ),
                                []
                            );
                        customLoadOptions.filter = filter || null;
                    }
                    if (itemTieuChi) {
                        customLoadOptions.sort = [
                            {
                                desc: itemTieuChi.kieuSapXep == "DESC",
                                selector: itemTieuChi.value,
                            },
                        ];
                    }
                    try {
                        let resp = await this.$Core.Api.QuanLyLenh(
                            this.$i18n.global.t(
                                "urlDNQuanLyLenh.DanhSachLenhDangThucHien"
                            )
                        ).Post({
                            loadOptions: customLoadOptions,
                            custom: query,
                        });
                        if (resp.StatusCode === 200) {
                            let result = resp.Data.data;
                            return result;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        return [];
                    } finally {
                        this.stopLoading();
                    }
                },
                key || null
            );
            return load;
        },
        Reload_DanhSachLenhDaHoanThanh(
            store,
            { textTimKiem, listFieldSearch, query, key }
        ) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    this.startLoading();
                    let customLoadOptions = { ...loadOptions };
                    let itemTieuChi = store.state.DanhSachTieuChi.find(
                        (e) => e.order != 0
                    );
                    if (itemTieuChi != undefined) {
                        if ((itemTieuChi.value = "Not")) {
                            itemTieuChi.value = "GioXuatBen";
                        }
                    }
                    let filter = [];
                    if (textTimKiem) {
                        filter = listFieldSearch
                            .map((e) => [e, "contains", textTimKiem])
                            .reduce(
                                (filter, x, i, a) => (
                                    filter.push(x),
                                    i < a.length - 1 ? filter.push("or") : !0,
                                    filter
                                ),
                                []
                            );
                        customLoadOptions.filter = filter || null;
                    }
                    if (itemTieuChi) {
                        customLoadOptions.sort = [
                            {
                                desc: itemTieuChi.kieuSapXep == "DESC",
                                selector: itemTieuChi.value,
                            },
                        ];
                    }
                    try {
                        let resp = await this.$Core.Api.QuanLyLenh(
                            this.$i18n.global.t(
                                "urlDNQuanLyLenh.DanhSachLenhDaHoanThanh"
                            )
                        ).Post({
                            loadOptions: customLoadOptions,
                            custom: query,
                        });
                        if (resp.StatusCode === 200) {
                            let result = resp.Data.data;
                            return result;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        return [];
                    } finally {
                        this.stopLoading();
                    }
                },
                key || null
            );
            return load;
        },
        Reload_DanhSachLenhKhongHoanThanh(
            store,
            { textTimKiem, listFieldSearch, query, key }
        ) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    this.startLoading();
                    let customLoadOptions = { ...loadOptions };
                    let itemTieuChi = store.state.DanhSachTieuChi.find(
                        (e) => e.order != 0
                    );
                    if (itemTieuChi != undefined) {
                        if ((itemTieuChi.value = "Not")) {
                            itemTieuChi.value = "GioXuatBen";
                        }
                    }
                    let filter = [];
                    if (textTimKiem) {
                        // listFieldSearch.forEach((e, index) => {
                        //     let params = null;
                        //     if (index < listFieldSearch.length - 1) {
                        //         params = [[e, "contains", textTimKiem], "or"];
                        //     } else if (index == listFieldSearch.length - 1) {
                        //         params = [e, "contains", textTimKiem];
                        //     }
                        //     filter = filter.concat(params);
                        // });
                        filter = listFieldSearch
                            .map((e) => [e, "contains", textTimKiem])
                            .reduce(
                                (filter, x, i, a) => (
                                    filter.push(x),
                                    i < a.length - 1 ? filter.push("or") : !0,
                                    filter
                                ),
                                []
                            );
                        customLoadOptions.filter = filter || null;
                    }
                    if (itemTieuChi) {
                        customLoadOptions.sort = [
                            {
                                desc: itemTieuChi.kieuSapXep == "DESC",
                                selector: itemTieuChi.value,
                            },
                        ];
                    }
                    try {
                        let resp = await this.$Core.Api.QuanLyLenh(
                            this.$i18n.global.t(
                                "urlDNQuanLyLenh.DanhSachLenhKhongHoanThanh"
                            )
                        ).Post({
                            loadOptions: customLoadOptions,
                            custom: query,
                        });
                        if (resp.StatusCode === 200) {
                            let result = resp.Data.data;
                            return result;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        return [];
                    } finally {
                        this.stopLoading();
                    }
                },
                key || null
            );
            return load;
        },

        async Get_DanhSachLuongTuyen(store) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t(
                        "urlDNQuanLyLenh.DanhSachLuongTuyenCapTuyen"
                    )
                ).Get();
                this.stopLoading();
                let result = resp.Data.data;
                return result || [];
            } catch (ex) {
                this.stopLoading();
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async Get_DanhSachXeDuKienTheoKeHoach(store, payload) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t(
                        "urlDNQuanLyLenh.DanhSachXeDuKienTheoKeHoach"
                    ),
                    {
                        IdKeHoach: payload.IdKeHoach,
                    }
                ).Get();
                this.stopLoading();
                let result = resp.Data.data;
                return result || [];
            } catch (ex) {
                this.stopLoading();
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },
        async Get_DanhSachLaiXeDuKienTheoKeHoach(store, payload) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t(
                        "urlDNQuanLyLenh.DanhSachLaiXeDuKienTheoKeHoach"
                    ),
                    {
                        IdKeHoach: payload.IdKeHoach,
                    }
                ).Get();
                this.stopLoading();
                let result = resp.Data.data;
                store.commit("SET_DANH_SACH_LAI_XE", result);
                return result || [];
            } catch (ex) {
                this.stopLoading();
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },
        async ChiTietKeHoach(store, { query }) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t("urlDNQuanLyLenh.ChiTietKeHoach"),
                    {
                        TuNgay: this.$Helper.getStartDay(query.TuNgay),
                        DenNgay: this.$Helper.getEndDay(query.DenNgay),
                        idKeHoach: query.IdKeHoach,
                    }
                ).Get();
                this.stopLoading();
                let result = resp.Data.data;
                if (query.changeKeHoach) {
                    store.commit("SET_CHI_TIET_KE_HOACH", result[0]);
                }
                return result || [];
            } catch (ex) {
                this.stopLoading();
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },
        async CapNhatKeHoach(store, query) {
            store;

            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t("urlDNQuanLyLenh.CapNhatKeHoach")
                ).Post(query);
                this.stopLoading();
                // let result = resp.Data.data;
                return resp.Data;
            } catch (ex) {
                this.stopLoading();
                return false;
            }
        },
        async TaoVaKyLenh(store, payload) {
            store;
            try {
                let resp = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t("urlDNQuanLyLenh.TaoVaKyLenh")
                ).Post(payload);
                return resp.Data;
            } catch (err) {
                console.log(err);
            }
        },
        async HuyLenh(store, payload) {
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t("urlDNQuanLyLenh.HuyLenh")
                ).Post({ IdLenh: payload.IdLenhDienTu });
                this.stopLoading();
                return resp;
            } catch (error) {
                console.log("🚀 ~ error", error);
            }
        },
        async Get_ChiTietLenh(store, { IdLenhDienTu }) {
            this.startLoading();
            try {
                let resp = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t("urlDNQuanLyLenh.ChiTietLenh"),
                    { IdLenhDienTu: IdLenhDienTu }
                ).Get();
                this.stopLoading();
                return resp;
            } catch (err) {
                console.log(err);
            }
        },
        async TaoChuyenDiBanVeTheoLenh(store, data) {
            try {
                let rs = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t(
                        "urlDNQuanLyLenh.TaoChuyenDiBanVeTheoLenh"
                    )
                ).Post(data);

                return rs.Data;
            } catch (err) {
                console.log(err);
            }
        },
        async KyLaiLenh(store, { IdLenhDienTu }) {
            try {
                let resp = await this.$Core.Api.KySoDienTu(
                    this.$i18n.global.t("urlDNQuanLyLenh.KyLaiLenh")
                ).Post({
                    idLenhDienTus: IdLenhDienTu,
                    idTaiKhoan: "00000000-0000-0000-0000-000000000000",
                    hoTen: "",
                });
                return resp;
            } catch (error) {
                console.log("🚀 ~ error", error);
            }
        },
        async DungHanhTrinh(store, { DataPost }) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t("urlDNQuanLyLenh.DungHanhTrinh")
                ).Post(DataPost);
                this.stopLoading();
                return resp;
            } catch (err) {
                console.log(err);
            }
        },
        async LayChiTietCauHinhDoanhNghiepTheoMaCauHinh(
            store,
            { DanhSachMaCauHinhCanLay }
        ) {
            try {
                let rs = await this.$Events.RequestPost({
                    keyRequest: "LayChiTietCauHinhDoanhNghiepTheoMaCauHinh",
                    ServicesBE: "QuanLyThongTinLenh",
                    url: this.$i18n.global.t(
                        "urlDNQuanLyLenh.ChiTietCauHinhDoanhNghiepTheoMaCauHinh"
                    ),
                    dataPost: {
                        maCauHinhs: DanhSachMaCauHinhCanLay,
                    },
                    msgSuccess: null,
                    msgError: "Lấy cấu hình doanh nghiệp thất bại!",
                    msgCatch: "Lấy cấu hình doanh nghiệp thất bại!",
                    typeNotificationSuccess: "Toast",
                    typeNotificationError: "Toast",
                });
                if (rs.status) {
                    let data = rs.data || [];
                    DanhSachMaCauHinhCanLay.forEach((e) => {
                        let item = data.find((_data) => _data.MaCauHinh == e);
                        store.state.ChiTietCauHinhDoanhNghiepTheoMaCauHinh[e] =
                            item?.GiaTri || null;
                    });
                }
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },
    },
};

function set(store, key, data) {
    store.commit("Set", {
        key: key,
        data: data,
    });
}
function resetChiTietKeHoach(store) {
    store.commit("Set", {
        key: "ChiTietKeHoach",
        data: {
            TenTuyen: null,
            TenBenXeDi: null,
            Not: "00:00:00",
        },
    });
}
// function unset(store, key) {
//     store.commit("Unset", {
//         key: key
//     });
// }
