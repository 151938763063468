import { AmLich } from "./AmLich";
import Devextreme from "@sonphat/devextreme";

const AutoFocusErrorHandlers = [
    wrapper => {
        let input = undefined;
        if ((input = wrapper.querySelector("input.dx-texteditor-input"))) {
            input.focus();
            return true;
        }
    },
    wrapper => {
        let input = wrapper.querySelector("input,text-area,select");
        if (input) {
            input.focus();
            return true;
        }
    }
];

export default function Helper({ $Core, emitter }) {
    Devextreme.$Core = $Core;
    this.autoFocusError = result => {
        if (
            result.brokenRules &&
            result.brokenRules.length &&
            result.brokenRules[0].validator &&
            result.brokenRules[0].validator._$element &&
            result.brokenRules[0].validator._$element.length
        ) {
            let wrapper = result.brokenRules[0].validator._$element[0];

            for (let AutoFocusErrorHandler of AutoFocusErrorHandlers) {
                if (AutoFocusErrorHandler && AutoFocusErrorHandler(wrapper)) {
                    break;
                }
            }
        }
    };
    this.ThongBaoToast = (type, msg) => {
        let ParamsError = {
            isVisible: true,
            message: msg,
            type: type
        };
        emitter.emit("onToast", ParamsError);
        console.log("🚀 ~ file: Helper.js:47 ~ Helper ~ emitter:", emitter);
    };
    this.ThongBaoPopup = ({
        type,
        message_title,
        message,
        title,
        textButton
    }) => {
        let ParamsError = {
            type: type || "Error",
            title: title || "Thông báo",
            message_title: message_title || "Lỗi!",
            message: message || "",
            textButton: textButton || "Đồng ý"
        };
        emitter.emit("ThongBaoPopup", ParamsError);
    };

    this.getLunarDate = (dd, mm, yy) => {
        return AmLich.getLunarDate(dd, mm, yy);
    };
    this.getLunarDateText = (dd, mm, yy) => {
        let data = AmLich.getLunarDate(dd, mm, yy);
        let day = data.day < 10 ? "0" + data.day : data.day;
        let month = data.month < 10 ? "0" + data.month : data.month;
        return day + "/" + month;
    };

    this.getYearCanChiText = yy => {
        return AmLich.getYearCanChi(yy);
    };

    this.getToday = () => {
        let date = new Date();
        let utc = Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            0
        );
        return new Date(utc);
    };

    this.getEndDay = date => {
        let tmpDate = new Date(date);
        return new Date(
            tmpDate.getFullYear(),
            tmpDate.getMonth(),
            tmpDate.getDate(),
            23,
            59,
            59
        );
    };

    this.getStartDay = date => {
        let tmpDate = new Date(date);
        return new Date(
            tmpDate.getFullYear(),
            tmpDate.getMonth(),
            tmpDate.getDate(),
            0
        );
    };
    this.formatDateTime = dateTime => {
        if (dateTime) {
            var tmp = new Date(dateTime);
            var HH = tmp.getHours();
            var mm = tmp.getMinutes();
            var dd = tmp.getDate();
            var MM = tmp.getMonth() + 1;
            HH < 10 ? (HH = "0" + HH) : HH;
            mm < 10 ? (mm = "0" + mm) : mm;
            dd < 10 ? (dd = "0" + dd) : dd;
            MM < 10 ? (MM = "0" + MM) : MM;
            var yyyy = tmp.getFullYear();
            return HH + ":" + mm + " " + dd + "/" + MM + "/" + yyyy;
        } else {
            return "";
        }
    };
    this.formatDate = dateTime => {
        if (dateTime) {
            var tmp = new Date(dateTime);
            var dd = tmp.getDate();
            var MM = tmp.getMonth() + 1;
            dd < 10 ? (dd = "0" + dd) : dd;
            MM < 10 ? (MM = "0" + MM) : MM;
            var yyyy = tmp.getFullYear();
            return dd + "/" + MM + "/" + yyyy;
        } else {
            return "";
        }
    };
    this.formatTime = dateTime => {
        if (dateTime) {
            var tmp = new Date(dateTime);
            var HH = tmp.getHours();
            var mm = tmp.getMinutes();
            HH < 10 ? (HH = "0" + HH) : HH;
            mm < 10 ? (mm = "0" + mm) : mm;
            return HH + ":" + mm;
        } else {
            return "";
        }
    };

    this.getDateString = date => {
        let tmpDate = date ? new Date(date) : new Date();
        return `${tmpDate.toISOString()}`;
    };

    this.getEndDateString = date => {
        let tmpDate = date ? new Date(date) : new Date();
        let utc = Date.UTC(
            tmpDate.getFullYear(),
            tmpDate.getMonth(),
            tmpDate.getDate(),
            16,
            59,
            59
        );
        return `${new Date(utc).toISOString()}`;
    };

    this.getUTCDateFromTimeString = timeString => {
        let time = timeString ? timeString.split(":") : ["0", "0"];
        let tmpDate = new Date();
        let date = new Date(
            tmpDate.getFullYear(),
            tmpDate.getMonth(),
            tmpDate.getDate(),
            time[0],
            time[1],
            0
        );
        return date.toISOString();
    };

    this.formatPhone = phoneNumber => {
        if (!phoneNumber) {
            return "";
        }
        let count = 0;
        let time = 0;
        let str = "";
        let tmpPhone = phoneNumber + "";
        tmpPhone = tmpPhone.trim();
        tmpPhone = tmpPhone.replaceAll(" ", "");
        if (tmpPhone.length < 9) {
            return tmpPhone || "";
        } else {
            for (let i = tmpPhone.length; i > 0; i--) {
                count++;
                if (count % 3 == 0 && time < 2) {
                    time++;
                    str = " " + tmpPhone[i - 1] + str;
                } else {
                    str = tmpPhone[i - 1] + str;
                }
            }
            return str || "";
        }
    };

    this.DecodeQrData_URL = url => {
        let u = new URL(url);
        return {
            MaUngDung: u.searchParams.get("MaUngDung"),
            MaChuyenDi: u.searchParams.get("MaChuyenDi"),
            MaDatCho: u.searchParams.get("MaDatCho"),
            LinkTraCuu: url
        };
    };

    this.Table = options_table => {
        return Devextreme.table(options_table);
    };
    this.Excel = options => {
        return Devextreme.excel(options);
    };
    this.KiemTraQuyen = (type, name) => {
        let DanhSachQuyen =
            $Core.AuthApi.TokenParsed &&
            $Core.AuthApi.TokenParsed.resource_access &&
            $Core.AuthApi.TokenParsed.resource_access[type] &&
            $Core.AuthApi.TokenParsed.resource_access[type].roles
                ? $Core.AuthApi.TokenParsed.resource_access[type].roles
                : [];
        return DanhSachQuyen.some(e => e == name);
    };
    this.FormatTextLichChay = (x, { Tuan, ThangDuong, ThangAm }) => {
        switch (x.LoaiLichChay) {
            case ThangDuong:
                return `Lịch dương - ${x.CacNgayChayTheoLich.join(", ")}`;
            case ThangAm:
                return `Lịch âm - ${x.CacNgayChayTheoLich.join(", ")}`;
            case Tuan:
                return (
                    `Lịch tuần - ` +
                    x.CacNgayChayTheoLich.sort()
                        .map(t => (t == 1 ? "Chủ nhật" : `Thứ ${t}`))
                        .join(", ")
                );
        }
        return "Chưa lập kế hoạch cho Nốt/Tài";
    };
    this.uuidv4 = () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (
                c ^
                (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
            ).toString(16)
        );
    };

    this.Common = Devextreme;
    this.DevExDataSource = Devextreme.DevExDataSource;
    this.DevExDataSourceCustom = Devextreme.DevExDataSourceCustom;
}
