import LenhDienTu from "./LenhDienTu";
class LaiXe extends LenhDienTu {
    constructor() {
        super();
        this.lyDo = "";
        this.danhSachHinhAnh = [];
    }
    get() {
        return this;
    }
    async dungHanhTrinh(guidLenh, ToaDo) {
        let self = this;
        if (!guidLenh) {
            throw new Error("Không có guidLenh");
        }
        if (!this.lyDo) {
            throw new Error("Không có lyDo");
        }
        window.$loading.show();
        //
        var formData = new FormData();
        formData.append("guidLenh", guidLenh);
        formData.append("lyDo", self.lyDo);
        formData.append("ToaDo", ToaDo);
        self.danhSachHinhAnh.forEach(f => {
            formData.append("DanhSachHinhAnh", f);
        });
        //
        let rs = await window.$Core.Api.LenhMobile(
            window.$i18n.global.t("url.LaiXeDungHanhTrinh")
        ).Post(formData);
        window.$loading.hide();
        return rs;
    }
    async tiepNhanLenh(guidLenh, ToaDo) {
        if (!guidLenh) {
            throw new Error("Không có guidLenh");
        }
        let rs = await window.$Core.Api.LenhMobile(
            window.$i18n.global.t("url.LaiXeTiepNhanLenh")
        ).Post({
            guidLenh: guidLenh,
            toaDo: ToaDo || ""
        });
        return rs;
    }
    async tuChoiLenh(guidLenh, ToaDo) {
        let self = this;
        if (!guidLenh) {
            throw new Error("Không có guidLenh");
        }
        if (!this.lyDo) {
            throw new Error("Không có lyDo");
        }
        window.$loading.show();
        let rs = await window.$Core.Api.LenhMobile(
            window.$i18n.global.t("url.LaiXeHuyNhanLenh")
        ).Post({
            guidLenh: guidLenh,
            lyDo: self.lyDo,
            toaDo: ToaDo || ""
        });
        window.$loading.hide();
        return rs;
    }
    async guiYeuCauKyLenh(guidLenh) {
        if (!guidLenh) {
            throw new Error("Không có guidLenh");
        }
        window.$loading.show();
        let rs = await window.$Core.Api.LenhMobile(
            window.$i18n.global.t("url.GuiYeuCauBenKyLenh"),
            {
                guidLenh: guidLenh
            }
        ).Get();
        window.$loading.hide();
        return rs;
    }
}
export { LaiXe as default };
