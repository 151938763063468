"use strict";
/* eslint-disable */
const SP = window.SP;
var checkSetup = true;
var tokenParsed = JSON.parse(localStorage.getItem("token")) || null;
var isTokenExpired = false;
module.exports = function AuthClass($Core) {
    async function Login(taiKhoan, matKhau, ghiNhoDangNhap) {
        let config = SP["keycloak"];
        var kcRealm = config.Realm;
        var kcAdmUrl = config.URL;
        var clientId = config.ClientId;
        var resp = await fetch(
            `${kcAdmUrl}/realms/${kcRealm}/protocol/openid-connect/token`,
            {
                method: "POST",
                body: Object.entries({
                    grant_type: "password",
                    client_id: clientId,
                    username: taiKhoan,
                    password: matKhau,
                })
                    .map((x) => x.join("="))
                    .join("&"),
                headers: {
                    "Content-Type":
                        "application/x-www-form-urlencoded; charset=utf-8",
                },
            }
        );
        var json = await resp.json();
        if (!json.error) {
            tokenParsed = json;
            localStorage.setItem("token", JSON.stringify(json));
            if (ghiNhoDangNhap) {
                localStorage.setItem(
                    "slaixe_thongTinDangNhap",
                    JSON.stringify({
                        taiKhoan,
                        matKhau,
                        ghiNhoDangNhap,
                    })
                );
            }
            // luuIndexedDB(tokenParsed);
            window.location.href = "/";
            return true;
        }
        return false;
    }
    function parseJwt(token) {
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map(function (c) {
                    return (
                        "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                    );
                })
                .join("")
        );

        return JSON.parse(jsonPayload);
    }
    function checkRefreshTokenExpired(refreshTokenParsed) {
        let nowInSeconds = new Date().getTime() / 1000;
        return refreshTokenParsed.exp - nowInSeconds <= 40;
    }

    async function refreshToken() {
        let config = window.SP["keycloak"];
        var kcRealm = config.Realm;
        var kcAdmUrl = config.URL;
        var clientId = config.ClientId;
        var resp = await fetch(
            `${kcAdmUrl}/realms/${kcRealm}/protocol/openid-connect/token`,
            {
                method: "POST",
                body: Object.entries({
                    grant_type: "refresh_token",
                    client_id: clientId,
                    refresh_token: tokenParsed.refresh_token,
                })
                    .map((x) => x.join("="))
                    .join("&"),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
        var json = await resp.json();
        console.log("🚀 ~ file: AuthApi.js:84 ~ refreshToken ~ json:", json);
        isTokenExpired = false;
        if (!json.error) {
            tokenParsed = json;
            localStorage.setItem("token", JSON.stringify(json));
            return true;
        } else {
            Logout();
        }
    }
    function luuIndexedDB(data) {
        const dbName = "myDB";
        const collection = "notes";
        var request = window.indexedDB.open(dbName, 1);
        let db = null;
        // Nếu thành công. Tiến hành lấy notes về
        request.onsuccess = (event) => {
            db = request.result;
            var rq = db
                .transaction(collection, "readwrite")
                .objectStore(collection)
                .add(data);
            rq.onsuccess = (event) => {
                // Lấy lại notes sau khi đã thêm giá trị mới
            };
        };

        // Kết nối lần đầu. Tiến hành khởi tạo objectStore với tên collection
        request.onupgradeneeded = (event) => {
            db = event.target.result;
            db.createObjectStore(collection, {
                autoIncrement: true,
            });
        };
    }
    async function Logout() {
        isTokenExpired = false;
        localStorage.removeItem("token");
        window.location.href = "/dang-nhap";
    }
    async function Setup() {
        try {
            if (tokenParsed && checkSetup) {
                setInterval(() => {
                    if (
                        checkRefreshTokenExpired(
                            parseJwt(tokenParsed.access_token)
                        )
                    ) {
                        isTokenExpired = true;
                        refreshToken();
                    } else {
                        isTokenExpired = false;
                    }
                }, 10 * 1000);
                checkSetup = false;
            }
        } catch (error) {}
    }
    Setup();
    Object.defineProperty(this, "Setup", {
        get: () => Setup,
    });
    Object.defineProperty(this, "Login", {
        get: () => Login,
    });
    Object.defineProperty(this, "Logout", {
        get: () => Logout,
    });

    Object.defineProperties(this, {
        IsAuth: {
            get: () => !!tokenParsed && !isTokenExpired,
        },
        Token: {
            get: () =>
                !!tokenParsed && !isTokenExpired
                    ? tokenParsed.access_token
                    : null,
        },
        TokenParsed: {
            get: () =>
                !!tokenParsed && !isTokenExpired
                    ? parseJwt(tokenParsed.access_token)
                    : null,
        },
    });
};
