import BaoCao from "./stores/BaoCao";
import Ve from "./stores/Ve";
import QrCode from "./stores/QrCode";
import HangHoa from "./stores/HangHoa";
import Lenh from "./stores/Lenh";
import ThongTin from "./stores/ThongTin";
import NhanVienBanVe from "./stores/NhanVienBanVe";
import ChuyenDi from "./stores/ChuyenDi";
import DVVTLenh from "./stores/DVVTLenh";
import TaiKhoan from "./stores/TaiKhoan";
export default {
    modules: {
        BaoCao,
        Ve,
        QrCode,
        HangHoa,
        Lenh,
        ThongTin,
        NhanVienBanVe,
        ChuyenDi,
        DVVTLenh,
        TaiKhoan
    }
};
