export default {
    namespaced: true,
    state: () => ({
        DanhSachTangXe: [],
        SoDoCho: [],
        DanhSachDiemDungCuaLoTrinh: [],
        DanhSachChoNgoiBanVe: [],
        DanhSachChoDangChonTheoXe: [
            // Example
            // {
            //     "IndexTang": 0,
            //     "DanhSachChoDangChonTheoTang": [
            //         {
            //             "IndexHang": 1,
            //             "IndexCot": 4
            //         },
            //         {
            //             "IndexHang": 5,
            //             "IndexCot": 4
            //         }
            //     ]
            // },
        ],
        DanhSachChoVoHieuHoaTheoXe: [
            // Example
            // {
            //     "IndexTang": 0,
            //     "DanhSachChoVoHieuHoaTheoTang": [
            //         {
            //             "IndexHang": 1,
            //             "IndexCot": 4
            //         },
            //         {
            //             "IndexHang": 5,
            //             "IndexCot": 4
            //         }
            //     ]
            // },
        ],
        reloadData: false,
        reloadThongTinBanVe: false,
    }),
    getters: {},
    mutations: {
        Set(state, { key, data }) {
            state[key] = data;
        },
        Unset(state, { key }) {
            delete state[key];
        },
    },

    actions: {
        async Get_DanhSachTangXe(store, idChuyenDi) {
            try {
                set(store, "DanhSachTangXe", []);
                set(store, "DanhSachChoDangChonTheoXe", []);
                set(store, "DanhSachChoVoHieuHoaTheoXe", []);
                let rs = await this.$Core.Api.ChuyenDi(
                    this.$i18n.global.t("url.DanhSachTangXeDuocUyThac"),
                    {
                        IdChuyenDi: idChuyenDi,
                    }
                ).Get();
                if (rs.Data.status) {
                    let DanhSachChoDangChonTheoXe = [];
                    let DanhSachChoVoHieuHoaTheoXe = [];
                    let data = rs.Data.data.map((e, i) => {
                        let item = {
                            SoCot: e.soCot,
                            SoHang: e.soHang,
                            IdTang: e.idTang,
                            TenTang: e.tenTang,
                            active: i == 0 ? true : false,
                        };
                        let tang = {
                            IndexTang: i,
                            DanhSachChoDangChonTheoTang: [],
                        };
                        let tangVoHieuHoa = {
                            IndexTang: i,
                            DanhSachChoVoHieuHoaTheoTang: [],
                        };
                        DanhSachChoDangChonTheoXe.push(tang);
                        DanhSachChoVoHieuHoaTheoXe.push(tangVoHieuHoa);
                        return item;
                    });
                    set(store, "DanhSachTangXe", data || []);
                    set(
                        store,
                        "DanhSachChoDangChonTheoXe",
                        DanhSachChoDangChonTheoXe || []
                    );
                    set(
                        store,
                        "DanhSachChoVoHieuHoaTheoXe",
                        DanhSachChoVoHieuHoaTheoXe || []
                    );
                }
                return rs.Data;
            } catch (error) {
                console.log("🚀 ~ ", error);
            }
        },
        async Get_MaTranChoNgoi(store, query) {
            try {
                let rs = await this.$Core.Api.ChuyenDi(
                    this.$i18n.global.t("url.MaTranChoNgoiDuocUyThac"),
                    query
                ).Get();

                if (rs.Data.status) {
                    let rsData = rs.Data.data;
                    let SoDoCho = [
                        {
                            IdTang: query.IdTang,
                            DanhSachChoNgoi: [],
                        },
                    ];
                    let DanhSachChoVoHieuHoaTheoXe =
                        store.state.DanhSachChoVoHieuHoaTheoXe;
                    DanhSachChoVoHieuHoaTheoXe[
                        query.IndexTang
                    ].DanhSachChoVoHieuHoaTheoTang = [];
                    rsData.forEach((e) => {
                        let data = {
                            GiaVe: e.giaTien,
                            IdCho: e.id,
                            IdTang: e.idTang,
                            KichHoatGhePhu: e.kichHoatGhePhu,
                            KieuCho: e.kieuCho,
                            LoaiCho: e.loaiCho,
                            SoDienThoaiKhachHang: e.soDienThoaiKhachHang,
                            TenCho: e.tenCho,
                            TrangThai: e.trangThai.idTrangThai,
                            IndexCot: e.viTriCot - 1,
                            IndexHang: e.viTriHang - 1,
                        };
                        SoDoCho[0].DanhSachChoNgoi.push(data);
                        if (e.trangThai.idTrangThai != 1) {
                            DanhSachChoVoHieuHoaTheoXe[
                                query.IndexTang
                            ].DanhSachChoVoHieuHoaTheoTang.push(data);
                        }
                    });
                    set(store, "SoDoCho", SoDoCho || []);
                    set(
                        store,
                        "DanhSachChoVoHieuHoaTheoXe",
                        DanhSachChoVoHieuHoaTheoXe || []
                    );
                } else {
                    set(store, "SoDoCho", []);
                    set(store, "DanhSachChoVoHieuHoaTheoXe", []);
                }
                return rs.Data;
            } catch (error) {
                console.log("🚀 ~ ", error);
            }
        },
        async Get_DanhSachDiemDungCuaLoTrinh(store, query) {
            try {
                let rs = await this.$Core.Api.DiemXuongCuaKhachHang(
                    this.$i18n.global.t("url.DanhSachDiemDungCuaLoTrinh"),
                    query
                ).Get();

                if (rs.Data.status) {
                    let rsData = rs.Data.data;
                    set(store, "DanhSachDiemDungCuaLoTrinh", rsData || []);
                } else {
                    set(store, "DanhSachDiemDungCuaLoTrinh", []);
                }
                return rs.Data;
            } catch (error) {
                console.log("🚀 ~ ", error);
            }
        },
        async LayGiaVeTheoChoNgoi(store, query) {
            try {
                let rs = await this.$Core.Api.ChuyenDi(
                    this.$i18n.global.t("url.LayGiaVeTheoChoNgoi"),
                    query
                ).Get();
                return rs.Data;
            } catch (error) {
                console.log("🚀 ~ ", error);
            }
        },
    },
};

function set(store, key, data) {
    store.commit("Set", {
        key: key,
        data: data,
    });
}
