<template>
    <div class="row justify-center text-xs-center font-16 font-medium">
        {{ Params.Message }}
    </div>

    <a
        :href="Params.LinkCapNhat"
        target="_blank"
        ref="LinkCapNhat"
        style="opacity: 0; position: fixed"
    ></a>

    <div
        class="row mt-3"
        :class="
            Params.CoPhienBanMoi && !Params.BatBuocCapNhat
                ? 'justify-space-between'
                : 'justify-center'
        "
    >
        <DxButton
            v-if="Params.CoPhienBanMoi && !Params.BatBuocCapNhat"
            text="Hủy"
            type="default"
            styling-mode="text"
            @click="Huy()"
        />
        <DxButton
            text="Cập nhật ngay"
            type="default"
            styling-mode="contained"
            @click="CapNhat()"
        />
    </div>
</template>

<script>
import { DxButton } from "devextreme-vue/button";
export default {
    components: {
        DxButton,
    },
    props: {
        Params: {
            type: Object,
            default: {
                Message: "",
                CoPhienBanMoi: false,
                BatBuocCapNhat: false,
                LinkCapNhat: "",
                CapNhatIos: false,
            },
        },
    },
    methods: {
        Huy() {
            this.$emit("DongPopup");
        },
        CapNhat() {
            if (this.Params.BatBuocCapNhat == false || this.Params.CapNhatIos) {
                this.$emit("DongPopup");
            }
            return this.Params.CapNhatIos
                ? null
                : SonPhat.hasOwnProperty("openExternal") &&
                      SonPhat.openExternal("https://cai-dat-slaixe.sbus.vn/");
            // this.$refs.LinkCapNhat.click()
        },
    },
};
</script>

<style></style>
