export default {
    namespaced: true,
    state: () => ({}),
    getters: {},
    mutations: {},

    actions: {
        async layChiTietLenhDangThucHien(state) {
            this.startLoading();
            let rs = await $Core.Api.LenhMobile(
                this.$i18n.global.t("url.LayChiTietLenhDangThucHien")
            ).Get();
            this.stopLoading();
            return rs;
        },

        async KetThucChuyenDi(state, dataPost) {
            this.startLoading();
            let rs = await $Core.Api.LenhMobile(
                this.$i18n.global.t("url.LaiXeHoanThanhHanhTrinh")
            ).Post(dataPost);
            this.stopLoading();
            return rs;
        },

        layDSTatCaLenh(store, query, key) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    this.startLoading();
                    try {
                        let resp = await this.$Core.Api.LenhMobile(
                            this.$i18n.global.t("url.LayDSTatCaLenh")
                        ).Post({
                            loadOptions: loadOptions || {},
                            custom: query,
                        });
                        if (resp.Data.status === true) {
                            let result = resp.Data.data;
                            this.stopLoading();
                            return result;
                        } else {
                            this.stopLoading();
                            return [];
                        }
                    } catch (ex) {
                        console.log("🚀 ~ file: KeHoach.js ~ line 57 ~ ex", ex);
                        this.stopLoading();
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    }
                },
                key || null
            );
            return load;
        },

        LayBaoCaoSuDungLenhLaiXe(store, query, key) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    this.startLoading();
                    try {
                        let resp = await this.$Core.Api.LenhMobile(
                            this.$i18n.global.t("url.LayDSTatCaLenh")
                        ).Post({
                            loadOptions: loadOptions || {},
                            custom: query,
                        });
                        if (resp.Data.status === true) {
                            let result = resp.Data.data;
                            this.stopLoading();
                            return result;
                        } else {
                            this.stopLoading();
                            return [];
                        }
                    } catch (ex) {
                        console.log("🚀 ~ file: KeHoach.js ~ line 57 ~ ex", ex);
                        this.stopLoading();
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    }
                },
                key || null
            );
            return load;
        },

        layDSLenhChuaThucHien(store, query, key) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    this.startLoading();
                    try {
                        let resp = await this.$Core.Api.LenhMobile(
                            this.$i18n.global.t("url.LayDSLenhChuaThucHien")
                        ).Post({
                            loadOptions: loadOptions || {},
                            custom: query,
                        });
                        if (resp.Data.status === true) {
                            let result = resp.Data.data;
                            this.stopLoading();
                            return result;
                        } else {
                            this.stopLoading();
                            return [];
                        }
                    } catch (ex) {
                        console.log("🚀 ~ file: KeHoach.js ~ line 57 ~ ex", ex);
                        this.stopLoading();
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    }
                },
                key || null
            );
            return load;
        },
        
        layDSLenhDangThucHien(store, query, key) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    this.startLoading();
                    try {
                        let resp = await this.$Core.Api.LenhMobile(
                            this.$i18n.global.t("url.LayDSLenhDangThucHien")
                        ).Post({
                            loadOptions: loadOptions || {},
                            custom: query,
                        });
                        if (resp.Data.status === true) {
                            let result = resp.Data.data;
                            this.stopLoading();
                            return result;
                        } else {
                            this.stopLoading();
                            return [];
                        }
                    } catch (ex) {
                        console.log("🚀 ~ file: KeHoach.js ~ line 57 ~ ex", ex);
                        this.stopLoading();
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    }
                },
                key || null
            );
            return load;
        },

        layDSLenhDaHoanThanh(store, query, key) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    this.startLoading();
                    try {
                        let resp = await this.$Core.Api.LenhMobile(
                            this.$i18n.global.t("url.LayDSLenhDaHoanThanh")
                        ).Post({
                            loadOptions: loadOptions || {},
                            custom: query,
                        });
                        if (resp.Data.status === true) {
                            let result = resp.Data.data;
                            this.stopLoading();
                            return result;
                        } else {
                            this.stopLoading();
                            return [];
                        }
                    } catch (ex) {
                        console.log("🚀 ~ file: KeHoach.js ~ line 57 ~ ex", ex);
                        this.stopLoading();
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    }
                },
                key || null
            );
            return load;
        },

        layDSLenhKhongHoanThanh(store, query, key) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    this.startLoading();
                    try {
                        let resp = await this.$Core.Api.LenhMobile(
                            this.$i18n.global.t("url.LayDSLenhKhongHoanThanh")
                        ).Post({
                            loadOptions: loadOptions || {},
                            custom: query,
                        });
                        if (resp.Data.status === true) {
                            let result = resp.Data.data;
                            this.stopLoading();
                            return result;
                        } else {
                            this.stopLoading();
                            return [];
                        }
                    } catch (ex) {
                        console.log("🚀 ~ file: KeHoach.js ~ line 57 ~ ex", ex);
                        this.stopLoading();
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    }
                },
                key || null
            );
            return load;
        },

        async LayDanhSachHanhKhachMuaVeTrenXe(state, idLenhDienTu) {
            try {
                let rs = await $Core.Api.ThongTin(
                    window.$i18n.global.t(
                        "urlQLTT.LayDanhSachHanhKhachMuaVeTrenXe"
                    ),
                    {
                        idLenhDienTu: idLenhDienTu,
                    }
                ).Get();
                return rs;
            } catch (error) {
                console.log("🚀 ", error);
            }
        },

        async LaiXeChuyenDoiChuyenDi(state, query) {
            try {
                let rs = await $Core.Api.LenhMobile(
                    window.$i18n.global.t("url.LaiXeChuyenDoiChuyenDi")
                ).Post(query);
                return rs;
            } catch (error) {
                console.log("🚀 ", error);
            }
        },
    },
};
