<template>
    <div slot="fixed">
        <div class="header">
            <div class="bg-style px-2">
                <ion-row
                    class="ion-justify-content-between ion-align-items-center pt-1"
                    style="height: 64px"
                >
                    <div class="row align-center">
                        <div
                            style="width: 32px; height: 32px; border-radius: 50%"
                            class="shrink boundary white--bg align-center justify-center"
                            @click="$router.push('/thong-bao')"
                        >
                            <img
                                src="../../assets/images/logo.png"
                                alt=""
                                style="width: 100%; height: auto; border-radius: 50px"
                            />
                        </div>
                        <div class="grow white--text pl-2 font-italic">
                            {{ model.TenTaiKhoan || "" }}
                        </div>
                        <div class="shrink">
                            <!-- <DxButton type="default" icon="mdi mdi-magnify" /> -->
                            <!-- @click="quetMa()" -->
                            <DxButton
                                type="default"
                                icon="mdi mdi-qrcode-scan"
                                @click="quetMa()"
                            />
                        </div>
                    </div>
                </ion-row>
                <div
                    style="
                        margin: 0 auto;
                        width: calc(100vw - 16px);
                        position: absolute;
                        top: 64px;
                        left: 8px;
                        z-index: 1;
                    "
                >
                    <QuangCaoVue
                        Height="100px"
                        style="border-radius: 10px"
                        :ShowNavButton="false"
                        :DataSource="data.QuangCao"
                        class="white--bg shadow"
                    />
                </div>
            </div>
        </div>
    </div>
    <div class="body" :style="data.QuangCao.length > 0 ? 'padding-top: 168px' : ''">
        <slot name="LenhDienTu"></slot>

        <div class="px-3">
            <div class="subtitle font-bold pb-0">Lệnh điện tử</div>
            <div>
                <ion-row style="flex-wrap: wrap">
                    <div
                        v-for="(item, index) in data.ChucNang"
                        :key="index"
                        :style="`order:${
                            item.viTri ? item.viTri : 9999
                        };width:80px;min-width:80px;`"
                        :class="item.class"
                        class="xs3 py-2"
                    >
                        <ion-row
                            class="ion-justify-content-center ion-align-items-center"
                            style="color: black"
                            @click="$router.push(item.chucNangUrl)"
                        >
                            <div>
                                <div
                                    style="width: 54px; height: 54px; border-radius: 12px"
                                    class="boundary row justify-center align-center"
                                >
                                    <i
                                        :class="`mdi mdi-${item.icon}`"
                                        :style="'color:' + item.maMau + ';font-size:30px'"
                                    ></i>
                                </div>
                            </div>
                            <div class="font-12 pt-1 text-xs-center">
                                {{ item.tenChucNang }}
                            </div>
                        </ion-row>
                    </div>
                </ion-row>
            </div>
        </div>
        <!-- 
        <div class="px-3 pt-3">
            <div class="subtitle font-bold pb-0">Vé điện tử</div>
            <div>
                <ion-row style="flex-wrap: wrap">
                    <div
                        v-for="(item, index) in data.ChucNang"
                        :key="index"
                        :style="`order:${
                            item.viTri ? item.viTri : 9999
                        };width:80px;min-width:80px;`"
                        :class="item.class"
                        class="xs3 py-2"
                    >
                        <ion-row
                            class="
                                ion-justify-content-center
                                ion-align-items-center
                            "
                            style="color: black"
                            @click="$router.push(item.chucNangUrl)"
                        >
                            <div>
                                <div
                                    style="
                                        width: 54px;
                                        height: 54px;
                                        border-radius: 12px;
                                    "
                                    class="
                                        boundary
                                        row
                                        justify-center
                                        align-center
                                    "
                                >
                                    <i
                                        :class="`mdi mdi-${item.icon}`"
                                        :style="
                                            'color:' +
                                            item.maMau +
                                            ';font-size:30px'
                                        "
                                    ></i>
                                </div>
                            </div>
                            <div class="font-12 pt-1">
                                {{ item.tenChucNang }}
                            </div>
                        </ion-row>
                    </div>
                </ion-row>
            </div>
        </div> -->
    </div>
</template>

<script>
import QuangCaoVue from "../../components/QuangCao";
import TaiKhoan from "../../../class/TaiKhoan";
import {} from "devextreme-vue"; // Méo hiểu sao bỏ cái này đi thì lỗi
import { searchOutline, qrCodeOutline } from "ionicons/icons";
import { IonIcon, IonRow, IonCard, IonCol, IonGrid } from "@ionic/vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { DxButton } from "devextreme-vue";
export default {
    layout: "application",
    components: {
        QuangCaoVue,
        IonIcon,
        IonRow,
        IonCard,
        IonCol,
        IonGrid,
        DxButton,
    },
    setup() {
        return {
            searchOutline,
            qrCodeOutline,
        };
    },
    props: {
        model: {
            type: Object,
            default: () => {},
        },
        data: {
            type: Array,
            default: () => {
                return {
                    ChucNang: [],
                    QuangCao: [],
                };
            },
        },
    },
    data() {
        return {};
    },
    methods: {
        ...mapActions("QrCode", ["quetMaQR"]),
        ...mapMutations("QrCode", ["setDataQuetMa", "setDangQuetMa", "setDataQuetMa"]),
        quetMa() {
            // let a =
            //     "https://vedientu.sbus.vn/api/TraCuu/TraCuuVe?MaUngDung=SONPHATSTICKET&MaChuyenDi=QNI.22.03.0017&MaDatCho=220310032150844BU8N0";
            // let b =
            //     "******531|NGUYEN HONG SON|1996******|0|163656245302466339|<*<**<<**********";
            // let c =
            //     "091849531|NGUYEN HONG SON|1996-04-21|0|163656245302466339|<1<00<<0973802338";
            // this.setDataQuetMa(c);
            // this.setDangQuetMa();
            this.quetMaQR();
        },
    },
    async created() {},
};
</script>
<style lang="scss" scoped>
.header {
    // height: 195px;
    // position: fixed;
    // top: 0;
    width: 100vw;
    // z-index: 9;
}
.body {
    padding-top: 124px;
    padding-bottom: 20px;
}
.lenh-ve-style {
    position: absolute;
    bottom: 0;
    border-radius: 10px;
    box-shadow: $box_shadow;
    width: 80%;
    z-index: 1;
    background-color: white;
}
.bg-style {
    position: relative;
    min-height: 144px;
    height: 152px;
    background-image: linear-gradient(#03a9f4, #bbdcea);
    border-bottom: 40px solid white;
    border-radius: 0px 0px 48px 48px;
}
.boundary:active {
    background-color: $border;
}
// .bg-style:after {
//     content: "";
//     position: absolute;
//     left: 0;
//     bottom: -21px;
//     width: 100%;
//     height: 0;
//     border-top: 32px solid #fff;
//     border-radius: 40%;
// }
</style>
<style scoped>
>>> .dx-button .dx-icon {
    color: #ffffff !important;
}
>>> .dx-gallery-wrapper {
    border-radius: 10px;
}
</style>
