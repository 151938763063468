import moment from "moment";

class LenhDienTu {
    constructor() {
        this.guidLenh = "";
        this.maLenh = "";
        this.bienKiemSoat = "";
        this.hieuLucTuNgay = new Date();
        this.hieuLucDenNgay = new Date();
        this.benDi = "";
        this.benDen = "";
        this.maTuyen = "";
        this.danhSachLaiXe = [
            {
                guidLaiXe: "",
                tenLaiXe: "",
                hangBangLai: "",
                daKyLenh: false
            }
        ];
        this.qrCode = "";
        this.trangThai = "";
        this.maMauTrangThai = "";
        this.hanPhuHieuTuyen = new Date();
        this.hanDangKiem = new Date();
        this.hanBaoHiem = new Date();
        this.tenDoanhNghiep = "";
        this.nguoiCapLenh = "";
        this.thoiGianCapLenh = new Date();
        this.soGhe = 0;
        this.soGiuong = 0;
        this.hinhThucChay = "";
        this.hanhTrinhChay = "";
        this.nhanVienPhucVuTrenXe = "";
        this.gioXuatBen = new Date();
        this.soKhachXuatBen = 0;
        this.gioDenBen = new Date();
        this.soKhachDenBen = 0;
        this.thuTruongDonViDaKyLenh = false;
        this.benDiDaKyLenh = false;
        this.benDenDaKyLenh = false;
        this.thoiGianDungHanhTrinh = new Date();
        this.laiXeDungHanhTrinh = "";
        this.lyDoDungHanhTrinh = "";
        this.danhSachHinhAnhSuCo = "";
        this.maChuyenDi = "";
        this.guidDoanhNghiep = "";
        this.tenTuyen = "";
        this.soKhach = "";
        this.thoiGianXuatBenKeHoach = new Date();
    }
    get() {
        return this;
    }
    async layLenhHienTai() {
        window.$loading.show();
        let rs = await window.$Core.Api.LenhMobile(
            window.$i18n.global.t("url.LayLenhHienTaiCuaLaiXe")
        ).Get();
        window.$loading.hide();
        if (rs.StatusCode === 200) {
            return rs.Data.data;
        }
        return this;
    }
    async layChiTietLenhDangThucHien() {
        window.$loading.show();
        let rs = await window.$Core.Api.LenhMobile(
            window.$i18n.global.t("url.LayChiTietLenhDangThucHien")
        ).Get();
        window.$loading.hide();
        return rs;
    }
    async layChiTietLenh() {
        if (!this.guidLenh) {
            throw new Error("Không có guidLenh");
        }
        window.$loading.show();
        let rs = await window.$Core.Api.LenhMobile(
            window.$i18n.global.t("url.LayChiTietLenh"),
            {
                GuidLenh: this.guidLenh
            }
        ).Get();
        window.$loading.hide();
        return rs;
    }
    async layLinkXemBanTheHienLenh() {
        if (!this.guidLenh) {
            throw new Error("Không có guidLenh");
        }
        window.$loading.show();
        let rs = await window.$Core.Api.LenhMobile(
            window.$i18n.global.t("url.LayLinkXemBanTheHienLenh"),
            { GuidLenh: this.guidLenh }
        ).Get();
        window.$loading.hide();
        if (rs.StatusCode === 200) {
            return rs.Data.data;
        }
        return this;
    }
    async LayDSTuyenVanChuyenTheoNgay(ngay) {
        if (!ngay) {
            throw new Error("Không có ngay");
        }
        window.$loading.show();
        let rs = await window.$Core.Api.LenhMobile(
            window.$i18n.global.t("url.LayDSTuyenVanChuyen"),
            { Ngay: ngay }
        ).Get();
        window.$loading.hide();
        return rs;
    }
    async LayDSGioXuatBenTheoNgay(ngay, idLuongTuyen) {
        if (!ngay) {
            throw new Error("Không có ngay");
        }
        if (!idLuongTuyen) {
            throw new Error("Không có idLuongTuyen");
        }
        window.$loading.show();
        let rs = await window.$Core.Api.LenhMobile(
            window.$i18n.global.t("url.LayDSGioXuatBen"),
            {
                Ngay: ngay,
                IdLuongTuyen: idLuongTuyen
            }
        ).Get();
        window.$loading.hide();
        return rs;
    }
    async laySoLuongLenh(
        idTab = 0,
        ngayXuatBenKeHoach = null,
        idLuongTuyen = null,
        danhSachGioXuatBen = []
    ) {
        // if (!idTab) {
        //     throw new Error("Không có idTab")
        // }
        window.$loading.show();
        let rs = await window.$Core.Api.LenhMobile(
            window.$i18n.global.t("url.LaySoLuongLenh"),
            {
                IdTab: idTab,
                NgayXuatBenKeHoach: ngayXuatBenKeHoach,
                IdLuongTuyen: idLuongTuyen,
                DanhSachGioXuatBen: danhSachGioXuatBen
            }
        ).Get();
        window.$loading.hide();
        return rs;
    }
    layDSLenhChuaThucHien(
        idLuongTuyen,
        ngayXuatBenKeHoach,
        danhSachGioXuatBen
    ) {
        const store = window.$Helper
            .Table({
                Id: "guidLenh",
                Url: window.$i18n.global.t("url.LayDSLenhChuaThucHien"),
                UrlConfig: window.SP["API_BaseUrl_DieuDo"],
                Stt: false // 'có dùng stt mặc định hay không'
            })
            .CustomStore()
            .Post({
                ngayXuatBenKeHoach: ngayXuatBenKeHoach
                    ? moment(ngayXuatBenKeHoach).format()
                    : null,
                idLuongTuyen: idLuongTuyen || null,
                danhSachGioXuatBen: danhSachGioXuatBen || null
            });
        return store.Store;
    }
    layDSLenhDaHoanThanh(idLuongTuyen, ngayXuatBenKeHoach, danhSachGioXuatBen) {
        const store = window.$Helper
            .Table({
                Id: "guidLenh",
                Url: window.$i18n.global.t("url.LayDSLenhDaHoanThanh"),
                UrlConfig: window.SP["API_BaseUrl_DieuDo"],
                Stt: false // 'có dùng stt mặc định hay không'
            })
            .CustomStore()
            .Post({
                ngayXuatBenKeHoach: ngayXuatBenKeHoach
                    ? moment(ngayXuatBenKeHoach).format()
                    : null,
                idLuongTuyen: idLuongTuyen || null,
                danhSachGioXuatBen: danhSachGioXuatBen || null
            });
        return store.Store;
    }
    layDSLenhKhongHoanThanh(
        idLuongTuyen,
        ngayXuatBenKeHoach,
        danhSachGioXuatBen
    ) {
        const store = window.$Helper
            .Table({
                Id: "guidLenh",
                Url: window.$i18n.global.t("url.LayDSLenhKhongHoanThanh"),
                UrlConfig: window.SP["API_BaseUrl_DieuDo"],
                Stt: false // 'có dùng stt mặc định hay không'
            })
            .CustomStore()
            .Post({
                ngayXuatBenKeHoach: ngayXuatBenKeHoach
                    ? moment(ngayXuatBenKeHoach).format()
                    : null,
                idLuongTuyen: idLuongTuyen || null,
                danhSachGioXuatBen: danhSachGioXuatBen || null
            });
        return store.Store;
    }
    danhSachTrangThai() {
        const trangThai = Object.freeze({
            CHO_KICH_HOAT: 1,
            CHO_BEN_DI_KY: 2,
            BEN_DI_DA_KY: 3,
            CHO_XAC_NHAN_TRA_KHACH: 4,
            HOAN_THANH: 5,
            KHONG_THUC_HIEN_HANH_TRINH: 6,
            KHONG_HOAN_THANH_HANH_TRINH: 7,
            HUY: 8
        });
        return trangThai;
    }
}
export { LenhDienTu as default };
