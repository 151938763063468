// import { createRouter, createWebHistory } from "vue-router";
import { createRouter, createWebHistory } from "@ionic/vue-router";
import Home from "./views/home";
// import defaultLayout from "./layouts/side-nav-inner-toolbar";
// import menuApplication from "./layouts/menu-application";
import application from "./layouts/application";
import defaults from "./layouts/default";
import none from "./layouts/none";
// import simpleLayout from "./layouts/single-card";
import TaiLenh from "./views/DNVT-Quan-Ly-Lenh/Tai-Lenh/index.vue";
function loadView(view) {
    return () => import(`./views/${view}.vue`);
}
function loadViewIndex(view) {
    return () => import(`./views/${view}/index.vue`);
}

export default function ($Core) {
    const router = createRouter({
        routes: [
            {
                path: "/tabs",
                name: "home",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("tabs"),
                children: [
                    {
                        path: "",
                        redirect: "/tabs/tab1",
                    },
                    {
                        path: "/tabs/tab1",
                        name: "tab1",
                        meta: {
                            requiresAuth: true,
                            layout: defaults,
                            title: "Sbus.vn",
                        },
                        component: loadView("Tab1"),
                    },
                    // {
                    //     path: "/tabs/tab2",
                    //     name: "tab2",
                    //     meta: {
                    //         requiresAuth: true,
                    //         layout: defaults,
                    //         title: "Sbus.vn"
                    //     },
                    //     component: loadView("DNVT-Quan-Ly-Lenh/index")
                    // },
                    {
                        path: "/tabs/tab3",
                        name: "tab3",
                        meta: {
                            requiresAuth: true,
                            layout: defaults,
                            title: "Sbus.vn",
                        },
                        component: loadView("Tab3"),
                    },
                    {
                        path: "/tabs/tab4",
                        name: "tab4",
                        meta: {
                            requiresAuth: true,
                            layout: defaults,
                            title: "Sbus.vn",
                        },
                        component: loadView("ca-nhan/index"),
                    },
                    {
                        path: "/tabs/LenhDienTu",
                        name: "LenhDienTu",
                        meta: {
                            requiresAuth: true,
                            layout: defaults,
                            title: "Sbus.vn",
                        },
                        component: loadView("Quan-Ly-Lenh/index"),
                        // component: loadView("Lenh-Dien-Tu")
                    },

                    // {
                    //     path: "/DanhSachLenh",
                    //     name: "DanhSachLenh",
                    //     meta: {
                    //         requiresAuth: true,
                    //         layout: defaults,
                    //         title: "Sbus.vn"
                    //     },
                    //     component: loadView("Quan-Ly-Lenh/index")
                    // },
                    {
                        path: "/tabs/HangHoa",
                        name: "HangHoa",
                        meta: {
                            requiresAuth: true,
                            layout: defaults,
                            title: "Sbus.vn",
                        },
                        component: loadView("Kiem-Soat-Hang-Hoa/index"),
                    },
                    {
                        path: "/thong-bao",
                        name: "thong-bao",
                        meta: {
                            requiresAuth: true,
                            layout: defaults,
                            title: "Sbus.vn",
                        },
                        component: loadView("thong-bao/index"),
                    },
                    {
                        path: "/Ban-Ve",
                        name: "Ban-Ve",
                        meta: {
                            requiresAuth: true,
                            layout: defaults,
                            title: "Sbus.vn",
                        },
                        component: loadView("Ban-Ve/index"),
                    },
                    // {
                    //     path: "/tabs/ban-ve-tren-xe",
                    //     name: "ban-ve-tren-xe",
                    //     meta: {
                    //         requiresAuth: true,
                    //         layout: defaults,
                    //         title: "Sbus.vn"
                    //     },
                    //     component: loadView("ban-ve-tren-xe")
                    // },
                ],
            },
            {
                path: "/tai-lenh",
                name: "taiLenh",
                meta: {
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("DNVT-Quan-Ly-Lenh/Tai-Lenh/index"),
                // component: loadView("Lenh-Dien-Tu")
            },
            // Báo cáo
            {
                path: "/bao-cao/ban-ve-cho-chuyen-di-danh-cho-lai-xe",
                name: "ban-ve-cho-chuyen-di-danh-cho-lai-xe",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView(
                    "bao-cao/ban-ve-cho-chuyen-di-danh-cho-lai-xe"
                ),
            },

            // Cá nhân
            {
                path: "/ca-nhan/co-quan-cap",
                name: "co-quan-cap",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("ca-nhan/co-quan-cap"),
            },
            {
                path: "/ca-nhan/dia-chi-thuong-tru",
                name: "dia-chi-thuong-tru",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("ca-nhan/dia-chi-thuong-tru"),
            },
            {
                path: "/ca-nhan/email",
                name: "email",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("ca-nhan/email"),
            },
            {
                path: "/ca-nhan/hang-bang",
                name: "hang-bang",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("ca-nhan/hang-bang"),
            },
            {
                path: "/ca-nhan/ho-va-ten",
                name: "ho-va-ten",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("ca-nhan/ho-va-ten"),
            },

            {
                path: "/ca-nhan/ngay-bat-dau-hieu-luc",
                name: "ngay-bat-dau-hieu-luc",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("ca-nhan/ngay-bat-dau-hieu-luc"),
            },
            {
                path: "/ca-nhan/ngay-cap",
                name: "ngay-cap",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("ca-nhan/ngay-cap"),
            },
            {
                path: "/ca-nhan/ngay-sinh",
                name: "ngay-sinh",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("ca-nhan/ngay-sinh"),
            },
            {
                path: "/ca-nhan/ngay-sinh",
                name: "ngay-sinh",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("ca-nhan/ngay-sinh"),
            },
            {
                path: "/ca-nhan/so-dien-thoai",
                name: "so-dien-thoai",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("ca-nhan/so-dien-thoai"),
            },
            {
                path: "/ca-nhan/so-giay-phep",
                name: "so-giay-phep",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("ca-nhan/so-giay-phep"),
            },
            {
                path: "/ca-nhan/so-nam-kinh-nghiem",
                name: "so-nam-kinh-nghiem",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("ca-nhan/so-nam-kinh-nghiem"),
            },
            {
                path: "/ca-nhan/thay-doi-mat-khau",
                name: "thay-doi-mat-khau",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("ca-nhan/thay-doi-mat-khau"),
            },
            {
                path: "/ca-nhan/thoi-gian-hieu-luc",
                name: "thoi-gian-hieu-luc",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("ca-nhan/thoi-gian-hieu-luc"),
            },
            {
                path: "/ca-nhan/thong-tin-ca-nhan",
                name: "thong-tin-ca-nhan",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("ca-nhan/thong-tin-ca-nhan"),
            },

            // Quản lý lệnh
            {
                path: "/DNVT-Quan-Ly-Lenh/chi-tiet",
                name: "DNVT-Quan-Ly-Lenh-chi-tiet",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("DNVT-Quan-Ly-Lenh/chi-tiet"),
            },

            {
                path: "/DNVT-Quan-Ly-Lenh",
                name: "DNVT-Quan-Ly-Lenh",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("DNVT-Quan-Ly-Lenh/index"),
            },
            {
                path: "/DNVT-Quan-Ly-Lenh/Ky-Lenh",
                name: "Ky-Lenh",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("DNVT-Quan-Ly-Lenh/Ky-Lenh/index"),
            },
            {
                path: "/DNVT-Quan-Ly-Lenh/Sua-Ke-Hoach",
                name: "Sua-Ke-Hoach",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("DNVT-Quan-Ly-Lenh/Sua-Ke-Hoach/index"),
            },
            {
                path: "/DNVT-Quan-Ly-Lenh/Chinh-Sua-Lenh",
                name: "Chinh-Sua-Lenh",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("DNVT-Quan-Ly-Lenh/Chinh-Sua-Lenh/index"),
            },
            {
                path: "/huong-dan-su-dung",
                name: "huong-dan-su-dung",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("Huong-Dan-Su-Dung/index"),
            },
            {
                path: "/huong-dan-su-dung/Xem-Chi-Tiet-Huong-Dan",
                name: "Xem-Chi-Tiet-Huong-Dan",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView(
                    "Huong-Dan-Su-Dung/Xem-Chi-Tiet-Huong-Dan/index"
                ),
            },
            {
                path: "/In-Ve-Lai-Xe-Ban",
                name: "In-Ve-Lai-Xe-Ban",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn"
                },
                component: loadViewIndex("In-Ve-Lai-Xe-Ban")
            },
            // {
            //     path: "/Dang-Nhap",
            //     name: "Dang-Nhap",
            //     meta: {
            //         requiresAuth: true,
            //         layout: none,
            //         title: "Sbus.vn"
            //     },
            //     component: loadViewIndex("Dang-Nhap")
            // },

            // {
            //     path: "/DNVT-Quan-Ly-Lenh",
            //     name: "DNVT-Quan-Ly-Lenh",
            //     meta: {
            //         requiresAuth: true,
            //         layout: defaults,
            //         title: "Sbus.vn"
            //     },
            //     component: loadView("DNVT-Quan-Ly-Lenh/index")
            // },

            // Thông báo
            {
                path: "/thong-bao/chi-tiet",
                name: "thong-bao-chi-tiet",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("thong-bao/chi-tiet"),
            },

            // Page
            {
                path: "/Ban-Ve/In-Ve",
                name: "Ban-Ve/In-Ve",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("Ban-Ve/In-Ve/index"),
            },
            {
                path: "/Ban-Ve/Thanh-Toan-QR-Code",
                name: "Ban-Ve/Thanh-Toan-QR-Code",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("Ban-Ve/Thanh-Toan-QR-Code/index"),
            },
            {
                path: "/Ban-Ve/Ban-Ve-Ghe-Phu",
                name: "Ban-Ve/Ban-Ve-Ghe-Phu",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("Ban-Ve/Ban-Ve-Ghe-Phu/index"),
            },
            {
                path: "/Ban-Ve/Thong-Tin-Thanh-Toan",
                name: "Ban-Ve/Thong-Tin-Thanh-Toan",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("Ban-Ve/Thong-Tin-Thanh-Toan/index"),
            },
            {
                path: "/Ban-Ve/Chon-Cho",
                name: "Ban-Ve/Chon-Cho",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("Ban-Ve/Chon-Cho/index"),
            },
            {
                path: "/ban-ve-tren-xe",
                name: "ban-ve-tren-xe",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("ban-ve-tren-xe"),
            },
            {
                path: "/Nhan-Hang-Hoa",
                name: "Nhan-Hang-Hoa",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("Kiem-Soat-Hang-Hoa/Nhan-Hang-Hoa/index"),
            },
            {
                path: "/Xem-Chi-Tiet-Hang-Hoa",
                name: "Xem-Chi-Tiet-Hang-Hoa",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView(
                    "Kiem-Soat-Hang-Hoa/Xem-Chi-Tiet-Hang-Hoa/index"
                ),
            },
            {
                path: "/ban-ve-ghe-phu",
                name: "ban-ve-ghe-phu",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("ban-ve-ghe-phu"),
            },
            {
                path: "/Chuyen-Doi-Lenh",
                name: "Chuyen-Doi-Lenh",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadViewIndex("Quan-Ly-Lenh/Chuyen-Doi-Lenh"),
            },
            {
                path: "/dang-nhap",
                name: "dang-nhap",
                meta: {
                    requiresAuth: false,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("dang-nhap-old"),
            },
            {
                path: "/Cap-Nhat-Webview",
                name: "Cap-Nhat-Webview",
                meta: {
                    requiresAuth: false,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("Cap-Nhat-Webview/index"),
            },
            {
                path: "/dang-ky",
                name: "dang-ky",
                meta: {
                    requiresAuth: false,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("dang-ky"),
            },
            {
                path: "/bao-cao/lich-su-ban-ve",
                name: "bao-cao/lich-su-ban-ve",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("bao-cao/lich-su-ban-ve"),
            },
            {
                path: "/bao-cao/so-do-cho",
                name: "bao-cao/so-do-cho",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("bao-cao/so-do-cho"),
            },
            {
                path: "/danh-sach-phuong-tien-da-van-hanh",
                name: "danh-sach-phuong-tien-da-van-hanh",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("danh-sach-phuong-tien-da-van-hanh"),
            },
            {
                path: "/ecosystem",
                name: "ecosystem",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("ecosystem"),
            },
            {
                path: "/quet-ma",
                name: "quet-ma",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("quet-ma"),
            },
            {
                path: "/so-do-cho",
                name: "so-do-cho",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("so-do-cho"),
            },
            {
                path: "/thanh-cong",
                name: "thanh-cong",
                meta: {
                    requiresAuth: true,
                    layout: none,
                    title: "Sbus.vn",
                },
                component: loadView("thanh-cong"),
            },
            {
                path: "/thanh-toan-bang-qrcode",
                name: "thanh-toan-bang-qrcode",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("thanh-toan-bang-qrcode"),
            },
            {
                path: "/thanh-toan-thanh-cong",
                name: "thanh-toan-thanh-cong",
                meta: {
                    requiresAuth: true,
                    layout: none,
                    title: "Sbus.vn",
                },
                component: loadView("thanh-toan-thanh-cong"),
            },
            {
                path: "/Danh-Sach-Lenh-Can-Hoan-Thanh",
                name: "Danh-Sach-Lenh-Can-Hoan-Thanh",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadViewIndex(
                    "Quan-Ly-Lenh/Danh-Sach-Lenh-Can-Hoan-Thanh"
                ),
            },

            // Báo cáo
            {
                path: "/Bao-Cao/Bao-Cao-Ban-Ve",
                name: "Bao-Cao/Bao-Cao-Ban-Ve",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadView("Bao-Cao/Bao-Cao-Ban-Ve/index"),
            },
            {
                path: "/Bao-Cao-Su-Dung-Lenh",
                name: "Bao-Cao-Su-Dung-Lenh",
                meta: {
                    requiresAuth: true,
                    layout: defaults,
                    title: "Sbus.vn",
                },
                component: loadViewIndex("Quan-Ly-Lenh/Bao-Cao-Su-Dung-Lenh"),
            },
            {
                path: "/",
                redirect: "/tabs",
            },
            {
                path: "/recovery",
                redirect: "/tabs",
            },
            {
                path: "/:pathMatch(.*)*",
                redirect: "/tabs",
            },
        ],
        history: createWebHistory(process.env.BASE_URL),
    });

    router.beforeEach((to, from, next) => {
        if (to.name === "dang-nhap" && $Core.AuthApi.IsAuth) {
            next({ name: "home" });
        }
        if (to.meta.requiresAuth) {
            if (!$Core.AuthApi.IsAuth) {
                next({ name: "dang-nhap" });
                // $Core.AuthApi.Login();
            } else {
                next();
            }
        } else {
            next();
        }
    });
    return router;
}
