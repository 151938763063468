<template>
    <ion-app>
        <ion-router-outlet />
    </ion-app>

    <DxToast
        v-model:visible="modelToast.visible"
        v-model:type="modelToast.type"
        width="auto"
        :position="{
            my: 'top center',
            at: 'top center',
            offset: '0 15',
        }"
        class="toast-style"
    >
        <template #contentTemplate>
            <ToastContentVue :toast="modelToast" />
        </template>
    </DxToast>
</template>
<script>
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { DxToast } from "devextreme-vue";
import ToastContentVue from "../components/_Common/ToastContent.vue";
export default {
    components: { IonApp, IonRouterOutlet, DxToast, ToastContentVue },
    data() {
        return {
            innerHeight: "",
            modelToast: {
                visible: false,
                message: "",
                type: "success",
            },
        };
    },
    computed: {},
    mounted() {
        this.innerHeight = window.innerHeight;
        let self = this;
        this.emitter.on("onToast", (params) => {
            self.modelToast.type = params.type;
            self.modelToast.visible = params.visible || params.isVisible;
            self.modelToast.message = params.message;
        });
    },
};
</script>